import schedule from "../images/Schedule.png"
import schedule1 from "../images/Schedule1.png"
// import location from "../images/DiChuyenDD.png"
// import GiaiThuong from "../images/GiaiThuong.png"

import Prize from "./Prizes"

function LichTrinh(){
    return(
        <div className='bg-[#0057CE] p-5'>
            <p className='uppercase font-extrabold text-white text-center text-2xl py-5'>
                Lịch trình
            </p>
            <img src={schedule} alt="" className="w-full mb-5 sm:hidden" />
            <img src={schedule1} alt="" className="w-full mb-5 max-sm:hidden" />
            {/* <img 
                className="w-full aspect-video bg-blue-500 rounded-xl"
                src={location}
                alt=""
            /> */}
            <Prize />
        </div>
    )
}

export default LichTrinh;