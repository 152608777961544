function TeamList(){
    function click(e){
        //Thay đổi content
        var id1 = e.target.id; //Lấy id của button đang chọn
        var content = "";
        switch(id1){
            case "rivers": 
                content = `
                    <li class="font-bold">
                        Tổng tư lệnh: <span class="font-normal">Ông Lê Đạt Nhân</span>
                    </li>
                    <li class="font-bold">
                        Đội trưởng: <span class="font-normal">Ông Nguyễn Tấn Phúc</span>
                    </li>
                    <li class="font-bold">
                        Phó thường trực: <span class="font-normal">Ông Trần Thanh Sang</span>
                    </li>
                    <li class="font-bold">
                        Đội phó: <span class="font-normal">Bà Nguyễn Thị Diễm Tuyết</span>
                    </li>
                    <li class="font-bold">
                        Đội phó: <span class="font-normal">Bà Nguyễn Thanh Hà</span>
                    </li>
                `; 
                break;
            case "farEast":
                content = `
                    <li class="font-bold">
                        Tổng tư lệnh: <span class="font-normal">Ông Huỳnh Trí Cường</span>
                    </li>
                    <li class="font-bold">
                        Đội trưởng: <span class="font-normal">Ông Nguyễn Thanh Phong</span>
                    </li>
                    <li class="font-bold">
                        Phó thường trực: <span class="font-normal">Ông Dương Bửu Khang</span>
                    </li>
                    <li class="font-bold">
                        Đội phó: <span class="font-normal">Bà Lê Thị Thùy Phương</span>
                    </li>
                    <li class="font-bold">
                        Đội phó: <span class="font-normal">Ông Nguyễn Đỗ Tùng</span>
                    </li>
                `;
                break;
            case "harmony": 
                content = `
                    <li class="font-bold">
                        Tổng tư lệnh: <span class="font-normal">Ông Vũ Quang Chính</span>
                    </li>
                    <li class="font-bold">
                        Đội trưởng: <span class="font-normal">Bà Nguyễn Thu Trang</span>
                    </li>
                    <li class="font-bold">
                        Phó thường trực: <span class="font-normal">Ông Lâm Trung Hiếu</span>
                    </li>
                    <li class="font-bold">
                        Đội phó: <span class="font-normal">Bà Nguyễn Thị Minh Phượng</span>
                    </li>
                    <li class="font-bold">
                        Đội phó: <span class="font-normal">Bà Lê Thị Hằng</span>
                    </li>
                `; 
                break;
            case "house": 
                content = `
                    <li class="font-bold">
                        Tổng tư lệnh: <span class="font-normal">Bà Huỳnh Thanh</span>
                    </li>
                    <li class="font-bold">
                        Đội trưởng: <span class="font-normal">Bà Đào Thùy Dương</span>
                    </li>
                    <li class="font-bold">
                        Phó thường trực: <span class="font-normal">Ông Lê Anh Tuấn</span>
                    </li>
                    <li class="font-bold">
                        Đội phó: <span class="font-normal">Bà Nguyễn Viết Thúy An</span>
                    </li>
                    <li class="font-bold">
                        Đội phó: <span class="font-normal">Ông Lê Sĩ Hải</span>
                    </li>
                `; 
                break;
            case "heart":
                content = `
                    <li class="font-bold">
                        Tổng tư lệnh: <span class="font-normal">Ông Võ Minh Khang</span>
                    </li>
                    <li class="font-bold">
                        Đội trưởng: <span class="font-normal">Ông Nguyễn Trọng Nghĩa</span>
                    </li>
                    <li class="font-bold">
                        Phó thường trực: <span class="font-normal">Bà Trần Thị Phương Thảo</span>
                    </li>
                    <li class="font-bold">
                        Đội phó: <span class="font-normal">Ông Lê Phạm Công Hoang</span>
                    </li>
                    <li class="font-bold">
                        Đội phó: <span class="font-normal">Bà Phan Thị Hồng Vân</span>
                    </li>
                `;
                break;
            default: content = "TeamBuilding HHH";
        }
        document.getElementById("content").innerHTML=content;

        //toogle class active
        var x = document.getElementById("tabnav").querySelectorAll(".active");
        x[0].classList.remove("active")
        document.getElementById(id1).classList.add("active");
    }
    return(
        <div className="mx-5">
            <p className="uppercase font-extrabold text-xl text-center text-[#0057CE] sm:text-5xl">
                Danh sách người dẫn đội
            </p>
            <div className="container my-5">
                <div id="tabnav" className="grid grid-flow-col auto-cols-auto">
                    <div 
                        id="rivers" 
                        onClick={click} 
                        className="text-center text-white self-end h-max text-sm font-bold bg-[#5AA0FF] py-1 rounded-t-lg cursor-pointer active"
                    >
                        RIVERS
                    </div>
                    <div 
                        id="farEast" 
                        onClick={click} 
                        className="text-center text-white self-end h-max text-sm font-bold bg-[#5AA0FF] py-1 rounded-t-lg cursor-pointer"
                    >
                        ĐÔNG A
                    </div>
                    <div 
                        id="harmony"
                        onClick={click}
                        className="text-center text-white self-end h-max text-sm font-bold bg-[#5AA0FF] py-1 rounded-t-lg cursor-pointer"
                    >
                        HARMONY
                    </div>
                    <div 
                        id="house"
                        onClick={click}
                        className="text-center text-white self-end h-max text-sm font-bold bg-[#5AA0FF] py-1 rounded-t-lg cursor-pointer"
                    >
                        HOUSE
                    </div>
                    <div 
                        id="heart"
                        onClick={click}
                        className="text-center text-white self-end h-max text-sm font-bold bg-[#5AA0FF] py-1 rounded-t-lg cursor-pointer"
                    >
                        HEART
                    </div>
                </div>
                <div className="p-2 bg-[#0057CE] rounded-b-lg">
                    <ul id="content" className="text-white list-disc ml-5">
                        <li className="font-bold">
                            Tổng tư lệnh: <span className="font-normal">
                                Ông Lê Đạt Nhân
                            </span>
                        </li>
                        <li className="font-bold">
                            Đội trưởng: <span className="font-normal">
                                Ông Nguyễn Tấn Phúc
                            </span>
                        </li>
                        <li className="font-bold">
                            Phó thường trực: <span className="font-normal">
                                Ông Trần Thanh Sang
                            </span>
                        </li>
                        <li className="font-bold">
                            Đội phó: <span className="font-normal">
                                Bà Nguyễn Thị Diễm Tuyết
                            </span>
                        </li>
                        <li className="font-bold">
                            Đội phó: <span className="font-normal">
                                Bà Nguyễn Thanh Hà
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default TeamList;