import Media1 from "../images/Media/Media1.png";
import Media2 from "../images/Media/Media2.png";
import Media3 from "../images/Media/Media3.png";
import Media4 from "../images/Media/Media4.png";
import Media5 from "../images/Media/Media5.png";
import Media6 from "../images/Media/Media6.png";
import Media7 from "../images/Media/Media7.png";
import Media8 from "../images/Media/Media8.png";
import Media9 from "../images/Media/Media9.png";

function handleChange(e){
    var main = document.getElementById("main");
    main.src = e.target.src;
}

function Media(){
    return(
        <div className="my-5">
            <p className="uppercase font-extrabold text-xl text-center text-[#0057CE] sm:text-5xl">
                Địa điểm Team Building
            </p>
            <p className="font-extrabold text-sm text-center text-[#F58220] sm:text-3xl">
                The Grand Ho Tram<br />
                Strip & Holiday Inn Resort Ho Tram Beach
            </p>
            <iframe
                title="facebook"
                className="w-full aspect-video my-5 rounded-xl"
                src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fhunghau.vn%2Fvideos%2F411536451947192%2F&width=500&show_text=false&appId=547330582698933&height=281" 
                style={{border: "none", overflow: "hidden"}} 
                scrolling="no" frameBorder="0" allowFullScreen={true} 
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
            ></iframe>
            <div className="max-sm:mx-5">
                <img
                    id="main"
                    className="w-full aspect-video my-3 bg-blue-500 rounded-lg" 
                    src={Media1}
                    alt="" 
                />
                <div className="no-wrap max-sm:overflow-x-scroll w-full sm:flex sm:justify-center">
                    <div className="flex w-max overflow-hidden">
                        <img 
                            className="w-[140px] aspect-video bg-blue-500 rounded-lg cursor-pointer mr-2" 
                            src={Media1}
                            alt=""
                            onClick={(e) => handleChange(e)}
                        />
                        <img 
                            className="w-[140px] aspect-video bg-blue-500 rounded-lg cursor-pointer mr-2" 
                            src={Media2}
                            alt=""
                            onClick={(e) => handleChange(e)}
                        />
                        <img 
                            className="w-[140px] aspect-video bg-blue-500 rounded-lg cursor-pointer mr-2" 
                            src={Media3}
                            alt=""
                            onClick={(e) => handleChange(e)}
                        />
                        <img 
                            className="w-[140px] aspect-video bg-blue-500 rounded-lg cursor-pointer mr-2" 
                            src={Media4}
                            alt=""
                            onClick={(e) => handleChange(e)}
                        />
                        <img 
                            className="w-[140px] aspect-video bg-blue-500 rounded-lg cursor-pointer mr-2" 
                            src={Media5}
                            alt=""
                            onClick={(e) => handleChange(e)}
                        />
                        <img 
                            className="w-[140px] aspect-video bg-blue-500 rounded-lg cursor-pointer mr-2" 
                            src={Media6}
                            alt=""
                            onClick={(e) => handleChange(e)}
                        />
                        <img 
                            className="w-[140px] aspect-video bg-blue-500 rounded-lg cursor-pointer mr-2" 
                            src={Media7}
                            alt=""
                            onClick={(e) => handleChange(e)}
                        />
                        <img 
                            className="w-[140px] aspect-video bg-blue-500 rounded-lg cursor-pointer mr-2" 
                            src={Media8}
                            alt=""
                            onClick={(e) => handleChange(e)}
                        />
                        <img 
                            className="w-[140px] aspect-video bg-blue-500 rounded-lg cursor-pointer" 
                            src={Media9}
                            alt=""
                            onClick={(e) => handleChange(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Media;