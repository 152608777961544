import buffet from "../images/Utilities/Buffet.png";
import KidQuater from "../images/Utilities/KidQuater.png";
import HoBoi from "../images/Utilities/HoBoi.png";
import Spa from "../images/Utilities/Spa.png";
import Yoga from "../images/Utilities/Yoga.png";
import GameCenter from "../images/Utilities/GameCenter.png";
import Gym from "../images/Utilities/Gym.png";
import Sport from "../images/Utilities/Sport.png";

function Utilities(){
    return(
        <div className="py-5 mx-5">
            <p className="uppercase font-extrabold text-xl text-center text-[#0057CE] sm:text-5xl">
                Tận hưởng các tiện ích
            </p>
            <div className="no-wrap max-sm:overflow-x-scroll w-full my-5 sm:mt-10 sm:flex sm:justify-center">
                <div className="flex w-max overflow-hidden">
                    <div className="w-[30vw] sm:w-[20vw] sm:mx-5">
                        <img 
                            className="w-[80px] h-[80px] bg-blue-500 rounded-full mx-auto mb-3" 
                            src={buffet}
                            alt="" 
                        />
                        <p className="text-sm text-center">
                            Buffet<br />
                            Ginger Restaurant
                        </p>
                    </div>
                    <div className="w-[30vw] sm:w-[20vw] sm:mx-5">
                        <img 
                            className="w-[80px] h-[80px] bg-blue-500 rounded-full mx-auto mb-3" 
                            src={KidQuater}
                            alt="" 
                        />
                        <p className="text-sm text-center">
                            Kid Quater
                        </p>
                    </div>
                    <div className="w-[30vw] sm:w-[20vw] sm:mx-5">
                        <img 
                            className="w-[80px] h-[80px] bg-blue-500 rounded-full mx-auto mb-3" 
                            src={HoBoi}
                            alt="" 
                        />
                        <p className="text-sm text-center">
                            Hồ bơi
                        </p>
                    </div>
                    <div className="w-[30vw] sm:w-[20vw] sm:mx-5">
                        <img 
                            className="w-[80px] h-[80px] bg-blue-500 rounded-full mx-auto mb-3" 
                            src={Spa}
                            alt="" 
                        />
                        <p className="text-sm text-center">
                            The Grand Spa
                        </p>
                    </div>
                    <div className="w-[30vw] sm:w-[20vw] sm:mx-5">
                        <img 
                            className="w-[80px] h-[80px] bg-blue-500 rounded-full mx-auto mb-3" 
                            src={Yoga}
                            alt="" 
                        />
                        <p className="text-sm text-center">
                            Yoga
                        </p>
                    </div>
                    <div className="w-[30vw] sm:w-[20vw] sm:mx-5">
                        <img 
                            className="w-[80px] h-[80px] bg-blue-500 rounded-full mx-auto mb-3" 
                            src={GameCenter}
                            alt="" 
                        />
                        <p className="text-sm text-center">
                            Khu chơi game
                        </p>
                    </div>
                    <div className="w-[30vw] sm:w-[20vw] sm:mx-5">
                        <img 
                            className="w-[80px] h-[80px] bg-blue-500 rounded-full mx-auto mb-3" 
                            src={Gym}
                            alt="" 
                        />
                        <p className="text-sm text-center">
                            Gym & Fitness Center
                        </p>
                    </div>
                    <div className="w-[30vw] sm:w-[20vw] sm:mx-5">
                        <img 
                            className="w-[80px] h-[80px] bg-blue-500 rounded-full mx-auto mb-3" 
                            src={Sport}
                            alt="" 
                        />
                        <p className="text-sm text-center">
                            Khu vực thể thao <br /> Central Park
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Utilities;