function Caution(){
    function click2(e){
        //Thay đổi content
        var id1 = e.target.id; //Lấy id của button đang chọn
        var content = "";
        switch(id1){
            case "dungGio":
                content = `
                    Tập trung tại HungHau House lúc 08h00 
                    và theo dõi các khung giờ quy định cho 
                    từng hoạt động đã thông báo ở mục Lịch 
                    trình. Việc đúng giờ sẽ giúp Quý Thầy 
                    Cô, Anh Chị tận hưởng được trọn vẹn 
                    mọi thứ đó nha!
                `; 
                break;
            case "hanhTrang":
                content = `
                    Hành lý gọn nhẹ, đầy đủ trang phục
                    cho các hoạt động team building, 
                    gala dinner. Các vật 
                    dụng cá nhân cơ bản.
                `;
                break;
            case "phuKien": 
                content = `
                    <p>
                        Giày dép phù hợp từng hoạt động 
                        (ví dụ: giày sandal, giày nhựa 
                        chống nước, chống trượt, giày 
                        thể thao…)
                    </p>
                    <p>
                        Để hạn chế các tác hại về da khi 
                        vui chơi tại công viên nước, Quý 
                        Thầy Cô, Anh Chị đừng quên chuẩn 
                        bị: găng tay, mũ nón, giày bơi 
                        (nếu có), kem chống nắng, kem 
                        chống muỗi…
                    </p>
                `; 
                break;
            case "tuTrang": 
                content = `
                    Để có thể tự do tận hưởng cuộc vui,
                    Quý Thầy Cô, Anh Chị lưu ý không 
                    mang theo quá nhiều vật dụng 
                    không cần thiết khi tham gia Team 
                    Building nhé.
                `; 
                break;
            case "anSang": 
                content = `
                    Quý Thầy Cô, Anh Chị hãy tranh thủ 
                    lấp đầy chiếc bụng của mình trước 
                    khi xuất phát nhé!
                `; 
                break;
            default: content = "TeamBuilding HHH";
        }
        document.getElementById("contentCaution").innerHTML=content;

        //toogle class active
        var x = document.getElementById("tabnavCaution").querySelectorAll(".active");
        x[0].classList.remove("active")
        document.getElementById(id1).classList.add("active");
    }
    return(
        <div className="mx-5 pt-10"> 
            <p className="uppercase font-extrabold text-xl text-center text-[#0057CE] sm:text-5xl">
                Lưu ý khi tham gia chuyến đi
            </p>
            <div className="container my-5">
                <div id="tabnavCaution" className="grid grid-flow-col auto-cols-auto">
                    <div 
                        id="dungGio" 
                        onClick={click2} 
                        className="text-center text-white self-end h-max text-sm font-bold bg-[#5AA0FF] py-1 rounded-t-lg cursor-pointer active"
                    >
                        Đúng giờ
                    </div>
                    <div 
                        id="hanhTrang" 
                        onClick={click2} 
                        className="text-center text-white self-end h-max text-sm font-bold bg-[#5AA0FF] py-1 rounded-t-lg cursor-pointer"
                    >
                        Hành trang
                    </div>
                    <div 
                        id="phuKien"
                        onClick={click2}
                        className="text-center text-white self-end h-max text-sm font-bold bg-[#5AA0FF] py-1 rounded-t-lg cursor-pointer"
                    >
                        Phụ kiện
                    </div>
                    <div 
                        id="tuTrang"
                        onClick={click2}
                        className="text-center text-white self-end h-max text-sm font-bold bg-[#5AA0FF] py-1 rounded-t-lg cursor-pointer"
                    >
                        Tư trang
                    </div>
                    <div 
                        id="anSang"
                        onClick={click2}
                        className="text-center text-white self-end h-max text-sm font-bold bg-[#5AA0FF] py-1 rounded-t-lg cursor-pointer"
                    >
                        Ăn sáng
                    </div>
                </div>
                <div className="p-2 bg-[#0057CE] rounded-b-lg text-white h-[200px] sm:h-[100px]">
                    <p id="contentCaution" className="text-justify">
                        Tập trung tại HungHau House lúc 08h00 
                        và theo dõi các khung giờ quy định cho 
                        từng hoạt động đã thông báo ở mục Lịch 
                        trình. Việc đúng giờ sẽ giúp Quý Thầy 
                        Cô, Anh Chị tận hưởng được trọn vẹn 
                        mọi thứ đó nha!
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Caution;