function Footer(){
    return(
        <div className="flex justify-center w-full bg-[#003A89]">
            <p className="text-center text-[#FFDD50] my-5">
                Chúc Quý Thầy Cô, Anh Chị & Người thân <br />
                có một chuyến đi thật vui tươi, hạnh phúc!
            </p>
        </div>
    )
}

export default Footer;