import location from "../images/location.png";
import timer from "../images/timer.png";

function Location(){
    return(
        <div className="bg-[#0057CE] rounded-xl h-full">
            <div className="flex items-center p-2 pl-0 h-2/3">
                <div className="w-[50px] sm:w-[80px] flex justify-center items-center">
                    <img src={location} alt="" className="w-[30px] h-[30px] sm:w-[80px] sm:h-[80px]" />
                </div>
                <p className="uppercase font-bold text-white text-[12px] sm:text-2xl">
                    The Grand Ho Tram strip <br className="sm:hidden" />
                    & Holiday Inn Resort <br className="sm:hidden" />
                    Ho Tram beach
                </p>
            </div>
            <div className="flex items-center p-2 pl-0 h-1/3 bg-[#CCE1FF] rounded-b-xl">
                <div className="w-[50px] sm:w-[80px] flex justify-center items-center">
                    <img src={timer} alt="" className="w-[15px] h-[15px] sm:w-[40px] sm:h-[40px]" />
                </div>
                <p className="uppercase font-bold text-[#0057CE] text-[12px] sm:text-xl">
                    15.09.2024 - 17.09.2024
                </p>
            </div>
        </div>
    )
}

export default Location;