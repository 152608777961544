import QRImg from '../images/InfoQR.png'

function QR(){
    return(
        <img 
            className="w-full"
            src={QRImg}
            alt="QR"
        />
        // <button className="bg-[#0057CE] rounded-xl text-white p-3">
        //     Click để xem thông tin
        // </button>
    )
}

export default QR;