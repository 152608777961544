import './App.css';

import Banner from './components/Banner';
import QR from './components/QR';
import Location from './components/Location';
// import Countdown from './components/Countdown';
import HuongDanMyU from './components/HuongDanMyU';
import LichTrinh from './components/LichTrinh';
// import Map from './components/ggMap';
import Media from './components/Media';
import Utilities from './components/Utilities';
import TeamList from './components/TeamList';
import Costume from './components/Costume';
import Caution from './components/Caution';
import Footer from './components/Footer';


import cbImg from './images/countdownBottom.png'

function App() {
  return (
    <>
      <div className='container mx-auto'>
        <Banner />
        <div className='grid grid-cols-4 gap-3 place-items-center m-5'>
          <QR />
          <div className='col-span-3 w-full h-full'>
            <Location />
          </div>
        </div>
        <HuongDanMyU />
        {/* <Countdown /> */}
        <img src={cbImg} alt='' className='w-screen bg-[#0057CE]' />
        <LichTrinh />
        {/* <Map /> */}
        <Media />
        <Utilities />
        <TeamList />
        <Costume />
        <Caution />
        <Footer />
      </div>
    </>
  );
}

export default App;
