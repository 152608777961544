import background from "../images/background.png"

import title1 from "../images/prize/title1.png"
import title2 from "../images/prize/title2.png"
import title3 from "../images/prize/title3.png"
import title4 from "../images/prize/title4.png"

import prize1 from "../images/prize/1.png"
import prize2 from "../images/prize/2.png"
import prize3 from "../images/prize/3.png"
import prize4 from "../images/prize/4.png"
import prize5 from "../images/prize/5.png"
import prize6 from "../images/prize/6.png"
import prize7 from "../images/prize/7.png"
import prize8 from "../images/prize/8.png"
import prize9 from "../images/prize/9.png"
import prize10 from "../images/prize/10.png"
import prize11 from "../images/prize/11.png"
import prize12 from "../images/prize/12.png"
import prize13 from "../images/prize/13.png"
import prize14 from "../images/prize/14.png"

function Prize(){
    return(
        <div className="bg-center bg-no-repeat bg-cover px-5" style={{backgroundImage: `url(${background})`}}>
            <img className="h-[50px] sm:h-[100px] mx-auto pt-5" src={title1} alt="" />
            <div className="grid grid-cols-2 sm:grid-cols-4">
                <img src={prize1} alt="" />
                <img src={prize2} alt="" />
                <img src={prize3} alt="" />
                <img src={prize4} alt="" />
            </div>
            <img className="h-[50px] sm:h-[100px] mx-auto pt-5" src={title2} alt="" />
            <div className="grid grid-cols-2 sm:grid-cols-4">
                <img src={prize5} alt="" />
                <img src={prize6} alt="" />
                <img src={prize7} alt="" />
                <img src={prize8} alt="" />
            </div>
            <img className="h-[50px] sm:h-[100px] mx-auto pt-5" src={title3} alt="" />
            <div className="grid grid-cols-2 sm:grid-cols-4">
                <img src={prize9} alt="" />
                <img src={prize10} alt="" />
                <img src={prize11} alt="" />
                <img src={prize12} alt="" />
            </div>
            <img className="h-[50px] sm:h-[100px] mx-auto pt-5" src={title4} alt="" />
            <p className="text-center text-white font-semibold sm:text-4xl">
                Dành tặng 30 thành viên tích cực nhất
            </p>
            <div className="grid grid-cols-2 sm:grid-cols-4">
                <div className="hidden sm:block"></div>
                <img src={prize13} alt="" />
                <img src={prize14} alt="" />
                <div className="hidden sm:block"></div>
            </div>
        </div>
    )
}

export default Prize;