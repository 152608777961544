import QRMyU from "../images/downloadMyU.png";

function HuongDanMyU(){
    function click1(e){
        //Thay đổi content
        var id1 = e.target.id; //Lấy id của button đang chọn
        var content = "";
        switch(id1){
            case "quetQR": 
                content = `
                    <div class="flex justify-center items-center">
                        <img 
                            class="w-[90px] bg-blue-500 mr-3 sm:mx-auto mb-3" 
                            src=${QRMyU}
                            alt="" 
                        />
                        <p class="text-justify">
                            Đặc biệt, Quý Thầy Cô, Anh Chị hãy tải 
                            app myU và đăng nhập tài khoản để dễ 
                            dàng tham gia các chương trình bình 
                            chọn vô cùng hấp dẫn tại đêm Gala Dinner. <br />
                            Tải app ngay:&nbsp;
                            <a 
                                href="https://myu.onelink.me/Rp8t/itf3vbo1"
                                class="underline"
                            >
                                Tại đây
                            </a>
                        </p>
                    </div>
                `; 
                break;
            case "huongDan":
                content = `
                    <ul class="text-white list-disc ml-5">
                        <li class="font-bold">
                            Bước 1: <span class="font-normal">Mở ứng dụng myU trên điện thoại</span>
                        </li>
                        <li class="font-bold">
                            Bước 2: <span class="font-normal">Đăng nhập/Đăng ký tài khoản myU</span>
                        </li>
                        <li class="font-bold">
                            Bước 3: <span class="font-normal">Dưới thanh công cụ của ứng dụng chọn "Quét QR" để checkin</span>
                        </li>
                    </ul>
                    <p>
                        Xem thêm hướng dẫn: 
                        <a 
                            href="https://sukien.myu.vn/sukien/teambuilding2024/checkin/"
                            class="underline"
                        >
                            Tại đây
                        </a>
                    </p>
                `;
                break;
            case "tienIch": 
                content = `
                    <ul class="text-white list-disc ml-5">
                        <li>
                            Thuận tiện tra cứu thông tin các hoạt động tại chương trình Team Building
                        </li>
                        <li>
                            Dễ dàng checkin Gala Dinner nhận mã số may mắn
                        </li>
                        <li>
                            Tham gia bình chọn tại Gala Dinner
                        </li>
                    </ul>
                `; 
                break;
            default: content = "TeamBuilding HHH";
        }
        document.getElementById("contentMyU").innerHTML=content;

        //toogle class active
        var x = document.getElementById("tabnavMyU").querySelectorAll(".active");
        x[0].classList.remove("active")
        document.getElementById(id1).classList.add("active");
    }
    return(
        <div className="mx-5 pt-10"> 
            <p className="uppercase font-extrabold text-xl text-center text-[#0057CE] sm:text-5xl">
                Hướng dẫn tải App MyU
            </p>
            <div className="container my-5">
                <div id="tabnavMyU" className="grid grid-flow-col auto-cols-auto">
                    <div 
                        id="quetQR" 
                        onClick={click1} 
                        className="uppercase text-center text-white self-end h-max text-sm font-bold bg-[#5AA0FF] py-1 rounded-t-lg cursor-pointer active"
                    >
                        Quét QR
                    </div>
                    <div 
                        id="huongDan" 
                        onClick={click1} 
                        className="uppercase text-center text-white self-end h-max text-sm font-bold bg-[#5AA0FF] py-1 rounded-t-lg cursor-pointer"
                    >
                        Hướng Dẫn
                    </div>
                    <div 
                        id="tienIch"
                        onClick={click1}
                        className="uppercase text-center text-white self-end h-max text-sm font-bold bg-[#5AA0FF] py-1 rounded-t-lg cursor-pointer"
                    >
                        Tiện ích
                    </div>
                </div>
                <div id="contentMyU" className="p-2 bg-[#0057CE] rounded-b-lg text-white">
                    <div className="flex justify-center items-center">
                        <img 
                            className="w-[90px] bg-blue-500 mr-3 sm:mx-auto mb-3" 
                            src={QRMyU} 
                            alt="" 
                        />
                        <p className="text-justify">
                            Quý Thầy Cô, Anh Chị hãy tải ứng dụng myU và đăng nhập tài khoản để thuận tiện tra cứu thông tin chương trình và tham gia bình chọn tại Gala Dinner. <br />
                            Tải app ngay:&nbsp;
                            <a 
                                href="https://myu.onelink.me/Rp8t/itf3vbo1"
                                className="underline"
                            >
                                Tại đây
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HuongDanMyU;