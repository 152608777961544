import costume from '../images/costume.png';

function Costume(){
    return(
        <div className="mx-5">
            <div className="py-5">
                <p className="uppercase font-extrabold text-xl text-center text-[#0057CE] sm:text-5xl">
                    Trang phục
                </p>
                <p className='pt-5'>
                    <span className='font-bold'>Trang phục tham gia TeamBuilding:</span> Áo đồng phục, nón HungHau & vòng tay nhận diện.
                </p>
                <p>
                    <span className='font-bold'>Trang phục tham gia Gala dinner:</span> Theo màu sắc nhận diện.
                </p>
                
            </div>
            <img
                className=""
                src={costume}
                alt=""
            />
        </div>
    )
}

export default Costume;