import banner from "../images/Banner.jpg"

function Banner(){
    return(
        <div className="flex justify-center w-full">
            <img 
                className="w-full aspect-video bg-blue-500"
                src={banner}
                alt=""
            />
        </div>
    )
}

export default Banner;